<template>
  <!-- <Header></Header> -->
  <div class="main-height">
    <section id="main">
      <div class="container-fluid">
        <div class="row vh-100">
          <div class="col-xxl-4 col-lg-5 col-md-6 p-md-5 p-4 ms-auto bg-white d-flex flex-column justify-content-md-center justify-content-center" id="signInForm" :style="{ marginTop: formMarginTop }">
            <img src="../assets/images/logo.svg" alt="img" class="mx-auto d-md-block d-none img-fluid" @click="this.$router.push('/')">
            <img src="../assets/images/logo-2.svg" alt="img" class="mx-auto d-md-none img-fluid mobileSignLogo" @click="this.$router.push('/')">
            <form class="mt-3" @submit.prevent="doSignIn">
              <div class="input-group mb-3 border-bottom align-items-center">
                <img src="../assets/images/user.svg" alt="icon" height="20" class="mx-2">
                <input type="text" class="form-control py-3" placeholder="Username" v-model="email">
              </div>
              <div class="input-group border-bottom align-items-center">
                <img src="../assets/images/lock.svg" alt="icon" height="20" class="mx-2">
                <input type="password" class="form-control py-3" placeholder="Password" v-model="password">
              </div>
              <div v-if="error.length" class="alert-danger mt-3 pe-3 text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>
                {{ error }}
              </div>
              <div class="mt-2  mb-5 text-end">
                <a role="button" class="forget" data-toggle="modal" @click="showModal = true">Forgot Password?</a>
              </div>
              <div id="btn">
                <button ref="signInButton" class="btn w-100 border-0 rounded-0 py-3 text-white fw-bold fs-3 shine" :class="{ 'disabled': isButtonDisabled }">
                  <span v-if="!loading">Sign In</span>
                  <div v-else class="spinner-container py-3 align-items-baseline">
                    <span class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    <span class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                  </div>
                </button>
              </div>
            </form>
          </div>
          <!--/col(1)-->
        </div>
        <!--/row-->
      </div>
      <!--/container-fluid-->
    </section>
  </div>
  <!-- forget password modal  -->
  <div class="modal d-block" v-if="showModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content ">
        <div class="modal-header align-self-center no-border">
          <h4 class="modal-title fw-bold" id="exampleModalLongTitle">Forgot Password</h4>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close" @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-0">
          <section class="forgetpassword-page">
            <div class="row">
              <div class="col-sm-12 col-12">
                <div class="register-form">
                  <div class="input-group mb-3 border-bottom align-items-center">
                    <img src="../assets/images/user.svg" alt="icon" height="20" class="mx-2">
                    <input v-model="modalEmail" required="" type="email" name="modalEmail" autocomplete="off" class="form-control py-3" placeholder="Email">
                  </div>
                </div>
                <div v-if="modalError.length" class="alert-danger mt-3 pe-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ modalError }}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="modal-footer align-self-center align-items-center no-border pt-0 ">
                  <div id="pop-btn">
                    <button role="button" data-toggle="modal" data-dismiss="modal" data-target="#exampleModal" class="btn w-100 border-0 rounded-0 py-2 px-5 text-white fw-bold fs-5 shine" :class="{ 'disabled': isButtonDisabled }" @click="doForgetPassword">
                      <span v-show="!modalloading">Submit</span>
                      <span v-show="modalloading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                      <span v-show="modalloading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                      <span v-show="modalloading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
      </div>
    </div>
  </div>
  <!-- popup modal  -->
  <div class="modal d-block" v-if="forgetPassword" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="card border border-0 w-100">
          <div class="row mx-0">
            <!-- LOGO  -->
            <div class="col-1 bg-success green"></div>
            <div class="col-2 d-flex justify-content-center align-self-center px-1">
              <img class="green-tick-img" src="../assets/green-tick.png" />
            </div>
            <!-- message  -->
            <div class="col-9 ps-2 vl">
              <p class="mb-0 mt-2 fw-bold">Success</p>
              <p class="popup-text mb-2 text-secondary">We’ve sent instructions on how to change your password to the following email address: <b>{{resetEmail}}</b>. Please do check your spam folder. </p>
            </div>
            <!-- close button  -->
            <div class="col-1 align-self-center text-center px-0">
              <!-- <a role="button" data-dismiss="modal" class="close">Close</a> -->
              <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" @click="forgetPassword=false">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <Footer></Footer> -->
</template>

<script>
import {defineComponent} from 'vue'
import AuthService from '@/services/auth-service'
import UsersService from '@/services/users-service'
import LeagueService from '@/services/league-service'
import { event } from 'vue-gtag'
import {PasswordResetRequest} from "@/model/models";

export default defineComponent({
  components: {
  },
  setup() {
    const login = () => {
      event('login', { method: 'Google' })
    }
    return {
      login
    }
  },
  data() {
    return {
      email: '',
      modalEmail: '',
      resetEmail: '',
      password: '',
      error: '',
      modalError: '',
      forgetPassword: false,
      showModal: false,
      showReadMore: false,
      loading: false,
      modalloading: false,
      isButtonDisabled: false,
      // formMarginTop: '0px',

    }
  },
  methods: {
    onEnter() {
      this.doSignIn();
    },
    doForgetPassword() {
      if (this.modalloading) return;
      this.modalloading = true;

      if (this.modalEmail == null || this.modalEmail === '') {
        this.modalError = "Please enter email address.";
        this.modalloading = false;
      } else {
        AuthService.initPasswordReset(new PasswordResetRequest(this.modalEmail)).then(()=> {
          localStorage.setItem('forgetPassword', "true")
          localStorage.setItem('resetEmail', this.modalEmail);
          window.location.reload();
        }).catch(() => {
          this.modalError = 'Email does not exist. Please try again with a valid email.';
          this.modalloading = false;
        })
      }
    },
    closeForgetPassword() {
      this.forgetPassword = false
    },
    doSignIn() {
      if (this.loading) return;
      this.loading = true;
      this.isButtonDisabled = true;
      AuthService.signIn(this.email, this.password).then(() => {
        this.doLoadPlans()
      }).catch(() => {
       this.error = 'Login failed';
       this.loading = false;
       this.isButtonDisabled = false;
      })
    },
    doLoadPlans() {
      UsersService.getUserStaticData().then(() => {
        UsersService.getUserProfile().then(() => {
          const userProfile = JSON.parse(localStorage.getItem('userProfile'))
          const userPlan = JSON.parse(localStorage.getItem('userPlan'))
          if (userProfile.planId != null && userProfile.planId != '' && !userPlan.trial
              && userProfile.planActive && userProfile.planStatus && userProfile.planEndDate >= Date.now()) {
            LeagueService.getLeagueList().then(() => {
              this.$router.push('/events')
            })
          } else {
            // logout
            localStorage.clear()
            // show modal
            this.error = 'We apologize, but access to the ProcPlayer app is exclusive to valid paid profiles. Please consider upgrading to enjoy the full version of our service.';
            this.loading = false;
            this.isButtonDisabled = false;
          }
        }).catch(() => {
          this.error = 'Login failed';
          this.loading = false;
        })
      }).catch(() => {
        this.error = 'Login failed';
        this.loading = false;
      })
    },
  },
  beforeMount() {
    if (localStorage.getItem('planExpired')) {
      this.error = 'We apologize, but access to the ProcPlayer app is exclusive to valid paid profiles. Please consider upgrading to enjoy the full version of our service.'
      localStorage.removeItem("planExpired")
    }
    if (localStorage.getItem('access_token')) {
      this.doLoadPlans()
    }
    if (localStorage.getItem('email')) {
      this.email = localStorage.getItem('email');
    }
    if (localStorage.getItem('sessionExpired')) {
      this.error = "Session expired. Log in again."
      localStorage.removeItem("sessionExpired")
    }
    if (localStorage.getItem('forgetPassword')) {
      this.forgetPassword=true
      this.resetEmail = localStorage.getItem('resetEmail')
      localStorage.removeItem("forgetPassword")
      localStorage.removeItem("resetEmail")
    }
    
    setTimeout(() => {
      this.forgetPassword = false;
    }, 7000);
  },
})

</script>

<style>
@import '../assets/css.css?v=1.0.1';
button.close.close-modal {background: white;border: 1px solid #fff;color: #333;position: absolute;right: 0;top: 0;font-size: 28px; border-radius: 5px;}
#btn{background: linear-gradient(90deg, rgba(30,208,214,1) 0%, rgba(0,80,166,1) 100%); min-height: 74px;}
#pop-btn{background: linear-gradient(90deg, rgba(30,208,214,1) 0%, rgba(0,80,166,1) 100%); min-height: 48px;}
.no-border{border: 0;}
</style>
